<template>
    <div>
        <div class="box">
            <DateRange
                :start.sync="start_date"
                :end.sync="end_date"
                @submit="loadQuotationTiers(start_date, end_date)"
            />

            <CustomTable
                id_table="quotation"
                :items="quotation"
                :busy.sync="table_busy"
                primaryKey="quotation_id"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
                :externSlotColumns="['preview','quotation_status']"
                :columsAddBegin="['preview']"
            >
                <template v-slot:[`custom-slot-cell(preview)`]="{ data }">
                    <a href="" class="" @click.prevent="quickPreview(data)">
                        <font-awesome-icon :icon="['fas', 'eye']" />
                    </a>
                </template>
                <template v-slot:[`custom-slot-cell(quotation_status)`]="{ data }">
                    {{ $t('invoice.devis.status.'+data.quotation_status) }}
                </template>
            </CustomTable>

            <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
                <template v-slot:modal-title>
                    {{ $t("action.previsualiser") }}
                </template>

                <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
                <div v-else>
                    <LoadingSpinner />
                </div>
            </b-modal>

            <AddDevis
                ref="add_devis_modal"
                :tiers_id="tiers_id"
            />

            <ModalSendQuotation ref="modal_send_quotation" @submit="devisSended"/>
            <ModalDuplicateQuotation ref="modal_duplicate_quotation" @submit="devisDuplicated"/>
        </div>
    </div>
</template>


<script type="text/javascript">
    import Quotation from "@/mixins/Quotation.js"
    import Navigation from "@/mixins/Navigation.js"
    import { EventBus } from "EventBus"

    export default {
        name: "TiersDevis",
        mixins: [Quotation, Navigation],
		props:['tiers_id'],
        data () {
            return {
                quotation: null,
                processing: false,
                display_zero: false,
                table_busy: true,
                start_date: null,
                end_date: new Date(),
                base64: null,
                events_tab: {
                    'TableAction::goToAddQuotation': () => {
                        this.$refs.add_devis_modal.openModal()
                    },
                    'TableAction::goToPdfQuotation': this.downloadPdf,
                    'TableAction::goToConvertToBAF': this.convertQuotation,
                    'TableAction::goToSendQuotation': this.openModalSendQuotation,
                    'TableAction::goToDuplicateQuotation': this.openModalDuplicate
                },
                config_table_hrefs: {
                    'author.tiers_rs': {
                        routeUniqueName: 'authorTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'author.tiers_id'
                        }
                    },
                    'tiers.tiers_rs': {
                        routeUniqueName: 'tiersTableauBord',
                        routeName: 'tiersTableauBord',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    },
                    'quotation_num': {
                        routeUniqueName: 'editDevis',
                        routeName: 'EditDevis',
                        params: {
                            quotation_id: 'quotation_id'
                        }
                    }
                }
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.start_date = new Date()
                this.start_date.setFullYear(this.start_date.getFullYear() - 1);

                this.loadQuotationTiers(this.start_date, this.end_date)
            },
            async loadQuotationTiers(start_date, end_date) {
                this.table_busy = true
                if(start_date) {
                    start_date = start_date.toDateInputValue()
                }
                if(end_date) {
                    end_date = end_date.toDateInputValue()
                }

                this.quotation = await this.getQuotationByTiers(this.tiers_id, start_date, end_date)

				this.table_busy = false
            },
            async quickPreview(quotation) {
                this.$refs.modelPreview.show()
                let retour = await this.getQuotationPdf(quotation.quotation_id, quotation.quotation_num, true)
                if(retour) {
                    this.base64 = retour
                }
            },
            async downloadPdf(quotation) {
                await this.getQuotationPdf(quotation.quotation_id, quotation.quotation_num)
                EventBus.$emit("TableAction::stopSpin")
            },
            async convertQuotation(quotation) {
                await this.convertQuotationToBAF(quotation.quotation_id)
                EventBus.$emit("TableAction::stopSpin")
                this.init_component()
            },
            openModalSendQuotation(quotation) {
                this.$refs.modal_send_quotation.openModal(quotation.quotation_id)
            },
            devisSended() {
                EventBus.$emit("TableAction::stopSpin")
                this.successToast('invoice.devis.send_success')
                this.loadQuotationTiers(this.start_date, this.end_date)
            },
            openModalDuplicate(quotation) {
                this.$refs.modal_duplicate_quotation.openModal(quotation.quotation_id)
            },
            devisDuplicated() {
                this.successToast("toast.info_modif_succes")
                this.loadQuotationTiers(this.start_date, this.end_date)
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            DateRange: () => import('GroomyRoot/components/Inputs/DateRange'),
            AddDevis: () => import('@/components/Invoice/AddDevis'),
            ModalSendQuotation : () => import('@/components/Invoice/ModalSendQuotation'),
            ModalDuplicateQuotation : () => import('@/components/Invoice/ModalDuplicateQuotation')
        }
    }
</script>
